import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'
import './Modal.scss'
import artists from './Artists'
import { BrowserView, MobileView } from 'react-device-detect';
import PreloadLCPImage from './PreloadLCPImage'
import logoIcon from './img/logo-aziimut---yellow.svg'
import spinner from './img/spinner-1.svg'

// https://upmostly.com/tutorials/modal-components-react-custom-hooks

// const ImgArtist = () => {

//   const [loading, setLoading] = useState(true);
//   const imgRef = useRef();
  
//   useEffect(() => {
//       if (loading) {
//           setTimeout(() => {
//               if (loading) {
//                   setLoading(false);
//               }
//           }, 100);
//       }
//   }, [loading, setLoading]);

//   useEffect(() => {
//     imgRef.current.src = loading ?
//         "https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif" :
//         imgSortais;
//     }, [loading, imgRef]);

//     return (
//     <div className="img-artist">
//         <img ref={imgRef} alt="okay" />
//     </div>
//   );
// }

const imgsPreloadList = [
  "./img/artists/Adda.jpg",
  "./img/artists/Bor.jpg",
  "./img/artists/Garcia.jpg",
  "./img/artists/Hadjab.jpg",
  "./img/artists/Herizi.jpg",
  "./img/artists/Issiakhem.jpg",
  "./img/artists/Legault.jpg",
  "./img/artists/Molinero.jpg",
  "./img/artists/Momein.jpg",
  "./img/artists/Tomek.jpg",
  "./img/artists/Paounoff.jpg",
  "./img/artists/Sortais.jpg",
  "./img/artists/Tersen.jpg",
  "./img/artists/Yarou.jpg",
  "./img/artists/Zohra.jpg"
];

const ArtistsListDesktop = () => {
  const [imgHidden, setImgHidden] = useState(true)
  const [imgUrl, setImgUrl] = useState('')
  const [isLoaded, setIsLoaded] = useState(false)

  const changeImgUrl = (url) => {
    setImgUrl(url);
  }

  useEffect(() => {
    for (const img in imgsPreloadList) {
      PreloadLCPImage(img)
    }
  }, [])

  const onLoad = () => {
    setIsLoaded(true)
  }

  return(
    <ul>
      {artists.map(artist => (        
        <>
        <li
          key={`id-${artist.id}`}
          onMouseEnter={() => {
            changeImgUrl(artist.img)
            setImgHidden(false)
          }}
          onMouseLeave={() => {
            setImgHidden(true)
            setIsLoaded(false)
          }}
        >{artist.first} {artist.last}</li>
        
        {!imgHidden && 
          <div className="img-artist-wrapper">
            <img className="img-artist" src={imgUrl} onLoad={onLoad} />
          </div>
        }
        </>
      ))}
      {!isLoaded && !imgHidden && <img className="img-loader" src={spinner}/>}
    </ul>
  )
}

const ArtistsListMobile = () => {
  return(
    <ul>
      {artists.map(artist => (        
        <ArtistMobile key={artist.last} artist={artist}/>
      ))}
    </ul>
  )
}

const ArtistMobile = (props) => {
  const {first, last, img} = props.artist;
  const [imgHidden, setImgHidden] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)

  const onLoad = () => {
    setIsLoaded(true)
  }

  return(
  <>
    <li
    className={imgHidden === false ? 'opened' : ''}
    key={last}
    onClick={() => {
      setImgHidden(!imgHidden)
    }}
    >{first} {last}</li>
    {!imgHidden && 
      <div className="img-artist-wrapper">
        {!isLoaded && !imgHidden && <img className="img-loader" src={spinner}/>}
        <img className="img-artist" src={img} onLoad={onLoad} />
      </div>
    }
  </>

  )
}

const ModalExhibitions = () => {
  return(
  <>
    <div className="modal-content modal-content--exhibitions">
      <section className="panel-1">
        <a href="https://thesalon.paris/" target="_blank">
          <h3>
            NADA & The Community
          </h3>
        </a>
        <h4>
          October 17th–20th, 2024 <br />
          30 bis Rue de Paradis
          75010 Paris France
        </h4>
      </section>
      <section className="panel-2">
        <a href="https://www.instagram.com/aziimutaziimut/" target="_blank">
          <h3>
            Aziimut 001
          </h3>
        </a>
        <h4>
          October 18th–20th, 2024 <br />
          81 Blvd Voltaire
          75011 Paris France
        </h4>
      </section>
    </div>
    <BrowserView>
      <ArtistsListDesktop />
    </BrowserView>
    <MobileView>
      <ArtistsListMobile />
    </MobileView>
  </>
)}

const ModalAbout = () => (
  <div className="modal-content modal-content--about">
    <div className="modal---valign">
      <div className={"icon"}>
        <img src={logoIcon} alt="Aziimut Logo Icon"/>
      </div>
      <p className={"text-1"}>Aziimut is developing traveling exhibitions <br />based on temporary urbanism.<br /><br />
The artistic collective’s commitment is to offer an alternative to the usual cultural consensus, capturing the essence of the ephemeral in a constantly changing world.<br /><br />
The long-term aim is to create an incubator for talent, supporting the production and presentation of new works by emerging and established local<br />and international artists.
      </p>
      <p className={"mail"}>contact@aziimut.com</p>
    </div>
  </div>
)

const ModalContact = () => (
  <div className="modal--wrapper">
    <p>contact@aziimut.com</p>
  </div>
)

const Modal = ({ isShowing, hide, modalType }) => isShowing ? ReactDOM.createPortal(
  <React.Fragment>
    <div className="modal-overlay" />
    <div className="modal-wrapper" aria-modal aria-hidden tabIndex={-1} role="dialog">
      <div className={`modal modal--${modalType}`}>
        <button onClick={hide} className="logo">AZIIMUT</button>
        <div className="modal-header">
          <button type="button" className="modal-close-button" data-dismiss="modal" aria-label="Close" onClick={hide}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {(() => {
          switch (modalType) {
            case 'contact':
              return <ModalContact />
            case 'exhibitions':
              return <ModalExhibitions />
            case 'about':
              return <ModalAbout />
            default:
              return null
          }
        })()}
      </div>
    </div>
  </React.Fragment>, document.body
) : null;

export default Modal;