import { useState, useEffect } from 'react'
import './App.scss'
import BgVideo from './video/video---def-noGrain.mp4'
// import BgVideo from './video/video---low.mp4'
import imgPoster from './video/video---def-noGrain-poster.jpg'
import logoIcon from './img/logo-aziimut---yellow.svg'
import Modal from "./Modal"
import useModal from './useModal'


const BackgroundVideo = () => {
  const [loading, setLoading] = useState(true);

  const handleLoadedData = () => {
    setLoading(false);
  };

  return (
    <div className="video-container">
      {loading && (
        <img
          src={imgPoster}
          alt="Loading poster"
          className="poster"
        />
      )}

      <video
        preload="auto"
        muted
        loop
        autoPlay
        playsInline
        onLoadedData={handleLoadedData}
        style={{ display: loading ? 'none' : 'block' }}
      >
        <source src={BgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}


const App = () => {
  const {isShowing, toggle} = useModal()
  const [modalType, setModalType] = useState()
  const [isPageLoaded, setIsPageLoaded] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsPageLoaded(true)
    }, 2500);
  }, []);



  return(
    <div className="App">
      {!isPageLoaded && 
        <div className="loader-page">
          <div className="loader-page---wrapper">
            <img src={logoIcon} alt="Aziimut Logo Icon"/>
            <p>loading</p>
          </div>
        </div>
      }

      <BackgroundVideo />

      <article>
        <button className="logo">AZIIMUT</button>
        <section className="wrapper--bottom">
          <button 
            className="link" 
            onClick={() => {toggle(); setModalType('exhibitions');}}>
              exhibitions
          </button>
          <button 
            className="link" 
            onClick={() => {toggle(); setModalType('about');}}>
              about
          </button>
          <button 
            className="link" 
            onClick={() => {toggle(); setModalType('contact');}}>
              contact
          </button>
        </section>
      </article>
      
      <a href="https://www.instagram.com/kafka.paris/" target="_blank" className="link-kafka"><span>by</span> KAFKA</a>

      <Modal
        isShowing={isShowing}
        hide={toggle}
        modalType={modalType}
      />
    </div>
  )
}

export default App;
